<template>
  <div>
    <div class="container">
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-button type="primary" plain @click="dialogClick">分配企业</el-button>
          <el-button type="primary" @click="addsuggestions">新增</el-button>
        </div>
        <div style="float: right">
          <div class="seach_box">
            <el-input
              placeholder="请输入标签组名称,标签组描述"
              v-model="queryInfo.queryConditions"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="changeSearchangeSearch">搜索</el-button>
            </el-input>
          </div>
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          @sort-change="changeSort"
          @selection-change="handleSelection"
          row-key="id"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column type="selection" align="center" :reserve-selection="true" />

          <el-table-column label="编号" align="center" prop="id"></el-table-column>

          <el-table-column label="服务建议图片" prop="coverImage" align="center">
            <template v-slot="{ row }">
              <el-image
                style="width: 100px; height: 50px"
                :preview-src-list="row.images"
                :src="row.images[0]"
                alt=""
              />
            </template>
          </el-table-column>

          <el-table-column label="服务建议文字" width="400" align="center">
            <template v-slot="{ row }">
              <span>{{ row.suggestion }}</span>
            </template>
          </el-table-column>

          <el-table-column label="服务建议标签" prop="label" align="center">
            <template v-slot="{ row }">
              <el-popover placement="left" width="430" trigger="hover">
                <div class="hidden_label_box">
                  <span class="edit" v-for="(el, index) in row.label" :key="index">{{ el }}</span>
                </div>
                <span slot="reference" class="edit" v-for="(el, index) in row.label" :key="index">
                  #{{ el }}</span
                >
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="上传人" prop="userName" align="center">
            <template v-slot="{ row }">
              <span>{{ row.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="上传时间" prop="createTime" align="center">
            <template v-slot="{ row }">
              <!--            <span>{{ row.createTime }}</span>-->
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>

          <el-table-column label="操作" prop="createTime" align="center">
            <template v-slot="{ row }">
              <span
                v-if="row.isShelf == 1 || row.isShelf == null"
                class="edit"
                @click="OnShelf(row)"
                >发布</span
              >
              <span v-if="row.isShelf == 0" class="frozen" @click="OffShelf(row)">下架</span>

              <span class="edit" @click="edit(row)">编辑</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog title="匹配企业" :visible.sync="dialogVisible" width="700px" class="account_dialog">
      <el-form label-width="100px" :model="pageParam" ref="form">
        <el-form-item label="所在地区:" prop="">
          <el-select v-model="pageParam.province" placeholder="请选择省份" @change="citychange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select
            v-model="pageParam.city"
            placeholder="请选择地区"
            @change="getSuggestionCompanyPage"
          >
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业全称" prop="link">
          <div class="disinput">
            <el-input
              placeholder="请输入企业全称"
              v-model="pageParam.companyFullName"
              class="input-with-select"
            >
              <el-button
                style="
                  border: 1px solid #448aff;
                  background-color: #448aff;
                  color: #fff;
                  border-radius: 0;
                "
                class="seach"
                slot="append"
                @click="getSuggestionCompanyPage()"
                >搜索</el-button
              >
            </el-input>
          </div>
        </el-form-item>
        <div class="add_search">
          <div class="left">
            <el-button style="margin-right: 5px" @click="reactClick">重置</el-button>
          </div>
        </div>
      </el-form>
      <el-table
        ref="multipleTables"
        v-loading="loading"
        element-loading-spinner="el-icon-loading"
        :data="listgisen"
        stripe
        width="100%"
        class="in_table"
        @selection-change="handleSelectionChange"
        row-key="id"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column type="selection" align="center" :reserve-selection="true" />

        <el-table-column label="企业头像" prop="" align="center">
          <template v-slot="{ row }">
            <img width="40" height="40" :src="row.companyLogo" alt="" />
          </template>
        </el-table-column>
        <el-table-column label="企业全称" prop="companyFullName" align="center"></el-table-column>
        <el-table-column label="所在地区" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.province }}-{{ row.city }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联运营商" prop="partnerName" align="center"></el-table-column>

        <el-table-column label="服务次数" prop="serviceNum" align="center"></el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="pageParam.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="pageParam.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSize"
          @current-change="handleCurrent"
        />
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  selectSuggestionPage,
  getSuggestionCompanyPage,
  offShelf,
  onShelf,
  updateCompanyParam
} from '@/api/channel'
import { getByFatherIdt, getProvince } from '@/api/demand'

import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  channelType: '',
  city: '',
  id: '',
  orderByParam: 'n.id DESC',
  pageNum: 1,
  pageSize: 10,
  province: '',
  queryConditions: ''
})
export default {
  name: 'Servicesuggestions',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      ids: [],
      dialogVisible: false, //详情\
      feedinfo: {},
      pageParam: {
        city: '',
        companyFullName: '',
        country: '',
        orderByParam: '',
        pageNum: 1,
        pageSize: 10,
        partnerId: '',
        province: ''
      },
      totaldins: 0,
      //匹配企业总
      idgisen: [],
      listgisen: [], //企业数据
      operatingList: [], //城市
      operatingLists: [] //省份
    }
  },
  created() {
    // this.search()
    if(this.$route.query.changeNum){
      this.queryInfo.pageNum = Number(localStorage.getItem('paginationCurrentPage'))
      this.search()
    }else{
      this.queryInfo.pageNum = 1
      this.search()
    }
  },
  methods: {
    //进去城市
    async getByFatherIdt(id) {
      const res = await getByFatherIdt({ provinceCode: id })
      if (res.data.resultCode == 200) {
        this.operatingLists = res.data.data
      }
    },
    //城市
    async incity() {
      const res = await getProvince()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },

    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await selectSuggestionPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.listData.forEach((el) => {
          if (el.label) {
            el.label = el.label.split(',')
          }
        })
        this.total = res.data.total
        this.loading = false
      }
    },
    //匹配企业一览
    async getSuggestionCompanyPage() {
      const { data: res } = await getSuggestionCompanyPage(this.pageParam)
      if (res.resultCode === 200) {
        this.listgisen = res.data.list
        this.totaldins = res.data.total
        this.loading = false
      }
    },
    //分配企业筛选条件重置
    reactClick() {
      ;(this.pageParam = {
        city: '',
        companyFullName: '',
        country: '',
        orderByParam: '',
        pageNum: 1,
        pageSize: 10,
        partnerId: '',
        province: ''
      }),
        this.getSuggestionCompanyPage()
    },
    //下架
    OffShelf(row) {
      const query = {
        id: row.id
      }
      this.$alert('是否确认下架服务建议？')
        .then(function () {
          return offShelf(query)
        })
        .then(() => {
          this.search()

          this.$message.success('下架成功')
        })
        .catch(() => {})
    },
    //上架
    OnShelf(row) {
      const query = {
        id: row.id
      }

      this.$alert('是否确认上架服务建议？')
        .then(function () {
          return onShelf(query)
        })
        .then(() => {
          this.search()

          this.$message.success('上架成功')
        })
        .catch(() => {})
    },
    //服务建议多选
    handleSelection(selection) {
      this.ids = selection.map((item) => item.id)
    },
    //分配企业多选
    handleSelectionChange(selection) {
      this.idgisen = selection.map((item) => item.id)
    },
    //分配企业
    submit() {
      if (this.idgisen.length > 0) {
        const query = {
          companyId: this.idgisen.toString(),
          ids: this.ids
        }
        const that = this
        this.$alert('是否确认分配企业？')
          .then(function () {
            return updateCompanyParam(query)
          })
          .then((res) => {
            if (res.data.resultCode == 200) {
              that.getSuggestionCompanyPage()
              that.idgisen = []
              this.$refs.multipleTables.clearSelection()
              this.$message.success('分配成功')
            } else {
              this.$message.error('分配失败')
            }
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
    citychange(item) {
      this.operatingList.forEach((o) => {
        if (this.pageParam.province == o.name) {
          this.pageParam.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
            console.log(this.operatingLists, 'this.operatingLists ')
          } else {
            this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
          }

          console.log(item, 'item', this.operatingList)
          // this.getByFatherIdt(o.code)
        }
      })
      this.search()
    },
    // 分页活动选择智参中心数据一览
    handleSize(val) {
      this.pageParam.pageSize = val
      this.getSuggestionCompanyPage()
    },
    // 分页活动选择智参中心数据一览
    handleCurrent(val) {
      this.pageParam.pageNum = val
      this.getSuggestionCompanyPage()
    },

    //分配企业
    dialogClick() {
      if (this.ids.length > 0) {
        this.dialogVisible = true
        this.getSuggestionCompanyPage()
        this.incity()
      } else {
        this.$message.warning('请选择服务建议')
      }
    },
    //新增
    addsuggestions() {
      this.$router.push({
        path: '/channel/suggestions'
      })
    },
    //编辑
    edit(row) {
      this.$router.push({
        path: '/channel/suggestions',
        query: {
          id: row.id,
          pageNum: this.queryInfo.pageNum
        }
      })
    },

    //排序
    changeSort(val) {
      //val里面返回prop和order
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 'k.id ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 'k.id DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 'k.id DESC'
          this.search()
        }
      }
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      localStorage.setItem('paginationCurrentPage', val)
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 80vh;
  overflow: scroll;
}
.seach_box {
  display: flex;
  margin: 15px 0;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
